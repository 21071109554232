
































































import { defineComponent, computed, toRefs } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { LineItemExtended } from '~/types/product/LineItemExtended';
import {
  useExtraGuarantee,
  useI18n,
  useCartSidebars,
  useCartSidebarStore,
  useExtraGuaranteeOptions,
  useLineItems,
} from '~/composables';
import { getItemPrice, getTotalPriceForItem, getTotalGrossPriceBeforeDiscount } from '~/helpers/cart/getTotalPrices';
import config from '~/config';
import type { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';
import { getEnergyRating } from '~/helpers/product/energyRating';

export default defineComponent({
  name: 'ProductCardVertical',
  components: {
    CartDiscountBanner: () => import(/* webpackChunkName: "CartDiscountBanner" */
      '~/components/molecules/Cart/CartDiscountBanner/CartDiscountBanner.vue'),
    ProductCardVerticalMobile: () => import(/* webpackChunkName: "ProductCardVerticalMobile" */
      '~/components/organisms/Product/ProductCardVertical/ProductCardVerticalMobile.vue'),
    ProductCardVerticalDesktop: () => import(/* webpackChunkName: "ProductCardVerticalDesktop" */
      '~/components/organisms/Product/ProductCardVertical/ProductCardVerticalDesktop.vue'),
  },
  props: {
    product: {
      type: Object as PropType<LineItemExtended>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showTotalPrice: {
      type: Boolean,
      default: false,
    },
    triggeredIn: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { product } = toRefs(props);
    const { languageAndCountry } = useI18n();
    const { hasOnlyOneOption } = useExtraGuaranteeOptions();
    const priceAfterDiscount = computed(() => product.value.unitPrice * product.value.qty);

    const totalPriceBeforeDiscountsForItem = computed(() =>
      getTotalPriceForItem(product.value),
    );

    const shouldDisplayDiscount = computed(() => totalPriceBeforeDiscountsForItem.value !== priceAfterDiscount.value);
    const {
      isVirtualProduct,
      setExtraGuaranteeInfo,
      extraGuaranteeParentSku,
      getItemFromSku,
    } = useExtraGuarantee();
    const { handleExtraGuaranteeLogic } = useCartSidebars();
    const { toggleCartSidebar } = useCartSidebarStore();
    const { setIsGuaranteeActionBeingClicked } = useLineItems();

    const productSku = computed(() => product.value.variant?.sku);
    const isVirtual = computed(() => isVirtualProduct(productSku.value));
    const showCartDiscountBanner = computed(() =>
      !props.readOnly &&
      !isVirtual.value &&
      product.value?.volumeDiscountsForGroup,
    );

    const energyRating = computed<EnergyRatingDetails>(() => {
      if (!product.value.variant) {
        return {};
      }

      return getEnergyRating({ product: product.value.variant, languageAndCountry: languageAndCountry.value });
    });

    const handleEditGuarantee = () => {
      const parentSku = extraGuaranteeParentSku(product.value);
      const parentItem = getItemFromSku(parentSku);
      setIsGuaranteeActionBeingClicked(true);
      setExtraGuaranteeInfo({
        price: getTotalGrossPriceBeforeDiscount(parentItem),
        name: parentItem.name,
        image: parentItem.variant?.images?.[0]?.url,
        sku: parentSku,
      });
      toggleCartSidebar();
      handleExtraGuaranteeLogic(productSku.value);
    };

    return {
      config,
      getItemPrice,
      productSku,
      isVirtual,
      showCartDiscountBanner,
      priceAfterDiscount,
      totalPriceBeforeDiscountsForItem,
      shouldDisplayDiscount,
      energyRating,
      handleEditGuarantee,
      hasOnlyOneOption,
    };
  },
});
